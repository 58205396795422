.layout {
    display: flex;
    height: 100vh;
}

.left-section, .right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-section {
    background-color: black;
    color: white;
    flex-direction: column;
}

.right-section {
    position: relative;
    background-color: #f1f1f1;
}

.right-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 14px;
}
