footer {
    background-color: black;
    color: white;
    padding: 10px;
    text-align: center;
}

footer p {
    margin: 5px 0;
}
